import APIService from '@/services/api-service'

class ScheduleService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getScheduledJobs () {
    return this.connector.get('/dispatch/scheduled-jobs')
  }
}

export default new ScheduleService()
