import APIService from '@/services/api-service'
const resource = '/dispatch'

class ActivityService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public update (data: any) {
    return this.connector.put(`${resource}/activity/update`, data)
  }

  public save (data: any) {
    return this.connector.post(`${resource}/activity/save`, data)
  }

  public delete (dispatchId: any, noteId: any, jobId: any) {
    return this.connector.delete(`${resource}/activity/tonnage/${dispatchId}/${noteId}/${jobId}`)
  }

  public getDispatchApprovals (fromLocationId) {
    return this.connector.get(`${resource}/approvals/${fromLocationId}`)
  }

  public getDispatchSeals (fromLocationId) {
    return this.connector.get(`${resource}/delivery/seals/${fromLocationId}`)
  }
}

export default new ActivityService()
