
import {
  Component,
  Vue
} from 'vue-property-decorator'
import BaseService from '../services/base-service'
import ActivityService from '../services/activity-service'
import ScheduleService from '../services/schedule-service'
import ItemService from '../services/item-service'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import {
  mask
} from 'vue-the-mask'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import Status from '@/components/Status/index.vue'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
  @Component({
    name: 'Dispatch',
    components: {
      Status
    },
    directives: {
      mask
    }
  })
export default class Dispatch extends Vue {
    protected isDispatchCreated = false
    protected isLoader = false
    protected isTableBusy = false
    protected searchTable = null
    protected contentTypes: any = []
    protected jobNumbers: any = []
    protected dispatch: any = {
      id: null,
      dispatch_date: null,
      notes: []
    }

    protected fields: any = [{
      key: 'vehicle_number',
      label: 'Vehicle No.',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vehicle_type',
      label: 'Type',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'vehicle_wheels',
      label: 'Wheels',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'vehicle_capacity',
      label: 'Capacity',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'dispatch_note_number',
      label: 'Note No.',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'trip_number',
      label: 'Trip No.',
      thStyle: {
        width: '8%'
      }
    },
    {
      key: 'commence_loading',
      label: 'Commence',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'completed_loading',
      label: 'Complete',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'content',
      label: 'Content',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '4%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.boot()
    }

    mounted () {
      this.dispatch.notes = this.dispatch.notes.map(notes => ({
        ...notes,
        isEdit: true
      }))
    }

    protected async boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populateJobNumbers()
      this.populateDispatch()
      this.populateContentTypes()
    }

    public populateDispatch () {
      this.dispatch.notes = []
      this.isTableBusy = true
      BaseService.getDispatch('Bowser').then((response) => {
        this.isTableBusy = false
        if (response.data === 'empty') {
          this.isDispatchCreated = false
          this.dispatch.notes = []
          return false
        }

        this.isDispatchCreated = true
        this.dispatch.id = response.data.id
        this.dispatch.dispatch_date = response.data.dispatch_date

        const notes: any = []
        response.data.notes.forEach((note) => {
          if (!note.is_vessel_dispatch) {
            if (note.status === 'open' || note.status === 'loaded') {
              notes.push({
                id: note.id,
                dispatch_id: note.dispatch_id,
                vehicle_number: note.vehicle_number,
                vehicle_type: note.vehicle_type,
                status: note.status,
                vehicle_wheels: note.vehicle_wheels,
                vehicle_capacity: note.vehicle_capacity,
                dispatch_note_number: note.dispatch_note_number,
                trip_number: note.trip_number,
                total_tonnage: note.total_tonnage === null ? 0 : note.total_tonnage,
                commence_loading: note.commence_loading,
                completed_loading: note.completed_loading,
                content_type: note.item_id === null ? {} : {
                  id: note.item_id,
                  name: note.content_type
                },
                validity: {
                  valid: false,
                  commence_loading: false,
                  completed_loading: false
                },
                lines: note.lines.length === 0 ? [{
                  isEdit: true,
                  id: null,
                  barge_id: null,
                  barge_name: null,
                  job_id: null,
                  job_number: null,
                  tonnage: null,
                  created_user: null,
                  modified_user: null
                }] : note.lines.map(line => ({
                  isEdit: false,
                  ...line
                }))
              })
            }
          }
        })

        const statusOrder = ['open', 'loaded']
        this.dispatch.notes = notes.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status))
      })
    }

    protected validateTime (index: number, key: any) {
      const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(this.dispatch.notes[index][key])
      if (isValid) {
        this.dispatch.notes[index].validity[key] = false
        this.dispatch.notes[index].validity.valid = true
        return true
      }
      this.dispatch.notes[index].validity[key] = true
      this.dispatch.notes[index].validity.valid = false
    }

    protected save (index: any, item: any) {
      if (item.commence_loading === null || item.completed_loading === null || Object.keys(item.content_type).length === 0) {
        return NotifyModule.set('Time fields might be empty, Please check...')
      }

      if (!this.validateTime(index, 'commence_loading') || !this.validateTime(index, 'completed_loading')) return NotifyModule.set('Incorrect time formats, Please check...')

      if (item.isExceed) return NotifyModule.set('Tonnage exceeds vehicle capacity, Please check...')
      this.isLoader = true

      const note: any = {
        id: item.id,
        dispatch_type: 'Bowser',
        dispatch_id: item.dispatch_id,
        status: 'loaded',
        is_vessel_dispatch: false,
        commence_loading: item.commence_loading,
        completed_loading: item.completed_loading,
        item_id: item.content_type.id,
        content_type: item.content_type.name,
        total_tonnage: item.total_tonnage,
        modified_user: AuthModule.user.fullname
      }
      const jobs: any = []
      item.lines.forEach((line) => {
        line.isEdit = false
        jobs.push({
          dispatch_note_id: item.id,
          line_id: line.id === null ? 0 : line.id,
          job_id: line.job_id,
          job_number: line.job_number,
          barge_id: AuthModule.party.id,
          barge_name: AuthModule.user.fullname,
          tonnage: line.tonnage,
          created_user: AuthModule.user.fullname,
          modified_user: AuthModule.user.fullname
        })
      })
      const data = { note: note, jobs: jobs }
      ActivityService.save(data).then((response) => {
        this.isLoader = false
        ToastModule.message(response.data.message)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected deleteTonnageDetails (tableIndex: any, item: any, index: number) {
      if (item.lines[index].job_id === null) {
        item.lines.splice(index, 1)
        this.calculateTotalTonnage(tableIndex)
      } else {
        if (confirm(`Are you sure you want to delete this Job ${item.lines[index].job_number}?`) === true) {
          ActivityService.delete(item.dispatch_id, item.id, item.lines[index].job_id).then((response) => {
            item.lines.splice(index, 1)
            this.calculateTotalTonnage(tableIndex)
            ToastModule.message(response.data.message)
          }).catch(error => {
            ToastModule.message(error.response.data.message)
          })
        }
      }
    }

    protected setJobNumber (tableIndex, index, jobId) {
      const job = this.jobNumbers.find(job => job.value === jobId)
      this.dispatch.notes[tableIndex].lines[index].job_number = job.text
    }

    protected calculateTotalTonnage (tableIndex: number) {
      this.dispatch.notes[tableIndex].total_tonnage = this.dispatch.notes[tableIndex].lines.reduce((total, line) =>
        total + parseFloat(line.tonnage), 0)

      const capacity = (parseInt(this.dispatch.notes[tableIndex].vehicle_capacity) / 1000)
      if (this.dispatch.notes[tableIndex].total_tonnage > capacity) {
        this.dispatch.notes[tableIndex].isExceed = true
      } else {
        this.dispatch.notes[tableIndex].isExceed = false
      }
    }

    protected addJobLine (tableIndex) {
      this.dispatch.notes[tableIndex].lines.push({
        isEdit: true,
        id: null,
        job_id: null,
        job_number: null,
        tonnage: null
      })
    }

    protected async populateJobNumbers () {
      const response = await ScheduleService.getScheduledJobs()
      response.data.forEach((element) => {
        this.jobNumbers.push({
          value: element.job_id,
          text: element.job_number
        })
      })
    }

    protected populateContentTypes () {
      ItemService.getInventoryContentTypes().then((response) => {
        response.data.forEach((item) => {
          this.contentTypes.push({
            value: {
              id: item.id,
              name: item.display_name
            },
            text: item.display_name
          })
        })
      })
    }
}

