import APIService from '@/services/api-service'
const resource = '/dispatch'

class BaseService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getDispatch (dispatchType: any) {
    return this.connector.get(`${resource}/notes/${dispatchType}`)
  }

  public create (header = {}, notes = []) {
    return this.connector.post(`${resource}/create`, { header: header, notes: notes })
  }

  public validateVehicleStatus (vehicleId: any) {
    return this.connector.get(`${resource}/validate-vehicle/${vehicleId}`)
  }

  public update (header: any, notes: any) {
    return this.connector.put(`${resource}/update`, { header: header, notes: notes })
  }

  public delete (id: any) {
    return this.connector.delete(`${resource}/note/${id}`)
  }

  public complete (dispatch: any) {
    return this.connector.post(`${resource}/complete`, dispatch)
  }
}

export default new BaseService()
