import APIService from '@/services/api-service'
const primary = '/dispatch'

class ItemService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getInventoryContentTypes () {
    return this.connector.get(`${primary}/inventory/content-types`)
  }

  public getWasteContentTypes () {
    return this.connector.get(`${primary}/waste/content-types`)
  }
}

export default new ItemService()
