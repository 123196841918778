
import {
  Component,
  Vue,
  Prop
} from 'vue-property-decorator'

  @Component({
    name: 'StatusBadge'
  })
export default class StatusBadge extends Vue {
    @Prop({
      type: String,
      default: 'yellow'
    }) color: any

    @Prop({
      type: String,
      default: 'Pending'
    }) text: any
}
